.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp .7s ,7s;
            animation-fill-mode: forwards;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #e3e3fc;
        height: 50px;
        font-size: 16px;
        // color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #e3e3fc;
        height: 50px;
        font-size: 16px;
        // color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: black;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid black;

        &:hover {
            background: #FFF;
            color: #000;
            transition: all 0.3s ease-out;
        }
    }
}



    .contact-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #000;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

        }

        p {
            font-size: 14px;
            color: #000;
            font-family: sans-serif;
            font-weight: 400;
            min-width: fit-content;
            // animation: pulse 1s;

            // &:nth-of-type(1) {
            //     animation-delay: 1.1s;
            // }

            // &:nth-of-type(2) {
            //     animation-delay: 1.2s;
            // }

            // &:nth-of-type(3) {
            //     animation-delay: 1.3s;
            // }
        }
    }

.tree-guy-contact {
    position: absolute;
    left: 120%;
    top: -70%;
    height: auto;
    width: 100%;
    z-index: 2;
    // animation: fadeIn 1.5s forwards;
}

.logo-contact {
    position: absolute;
    left: 135%;
    top: 50%;
    height: auto;
    width: 70%;
    z-index: 2;
    // animation: fadeIn 1.5s forwards;
}


//Media Queries

@media (max-width: 2560px) {
    // .container {
    //     flex-direction: column;
    //     align-items: center;
    // }

    // .contact-zone {
    //     width: 75%;
    // }

    // .image-container{
    //     margin-bottom: 20px;
    //     position: relative;
    // }
    // .image-container img{
    //     margin-left: 0;
    //     width: 80%;
    // }
    .tree-guy-contact {
        position: absolute;
        left: 120%;
        top: -30%;
        height: auto;
        width: 100%;
        z-index: 2;
        // animation: fadeIn 1.5s forwards;
    }
    // .logo-contact {
    //     position: absolute;
    //     display: none;
    //     left: 135%;
    //     top: 50%;
    //     height: auto;
    //     width: 70%;
    //     z-index: 2;
    //     // animation: fadeIn 1.5s forwards;
    // }
}

@media (max-width: 440px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .contact-zone {
        width: 75%;
    }

    .image-container{
        margin-bottom: 20px;
        position: relative;
    }
    .image-container img{
        margin-left: 0;
        width: 80%;
    }
    .tree-guy-contact {
        position: absolute;
        display: none;
        left: 120%;
        top: 20%;
        height: auto;
        width: 100%;
        z-index: 2;
        // animation: fadeIn 1.5s forwards;
    }
    .logo-contact {
        position: absolute;
        display: none;
        left: 135%;
        top: 50%;
        height: auto;
        width: 70%;
        z-index: 2;
        // animation: fadeIn 1.5s forwards;
    }
}