.home-page {

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #000;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        // <---------- HTML TAG Decor -------->
        // &::before {
        //     content: '<h1>';
        //     font-family: 'La Belle Aurore';
        //     color: #000;
        //     font-size: 18px;
        //     position: absolute;
        //     margin-top: -40px;
        //     left: 15px;
        //     opacity: 0.6;
        // }

        // &::after {
        //     content: '</h1>';
        //     font-family: 'La Belle Aurore';
        //     color: #000;
        //     font-size: 18px;
        //     position: absolute;
        //     margin-top: 18px;
        //     left: 20px;
        //     animation: fadeIn 1s 1.7s backwards;
        //     opacity: 0.6;
        // }

        // img {
        //     width: 32px;
        //     margin-left: 20px;
        //     opacity: 0;
        //     height: auto;
        //     animation: rotateIn 1s linear both;
        //     animation-delay: 1.4s;
        // }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 18px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;

        
    }

    .one-liner {
        color: #5C29DE !important;
        font-weight: 600;
        
    }

    .flat-button {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: 'sans-serif';
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #000;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #FFF;
            color: #000;
            transition: all 0.3s ease-out;
        }
    }
}


.home-art {
    position: absolute;
    left: 48%;
    top: 5%;
    height: auto;
    width: 35%;
    opacity: 0;
    z-index: 2;
    animation: fadeIn 1s 1.8s forwards;
}

//Media Queries

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .container {
        overflow: visible;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 30px;
            box-sizing: border-box;
        }

        // .flat-button {
        //     float: none;
        //     display: block;
        //     margin: 40px auto 0 auto;
        //     width: 165px;
        // }

        .home-art {
            position: absolute;
            width: 35%;
            height: auto;
            top: 5%;
            left: 48%;
            right: 0;
            box-sizing: border-box;
            margin: auto;
        }
    }
}

@media screen and (max-width: 1024px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .container {
        overflow: visible;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 30px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 40px auto 0 auto;
            width: 165px;
        }

        .home-art {
            position: relative;
            width: 60%;
            height: auto;
            top: 20px;
            left: 0;
            right: 0;
            box-sizing: border-box;
            margin: auto;
        }
    }
}

// @media (max-width: 440px) {
//     .home-art {
//         position: absolute;
//         left: 25%;
//         height: auto;
//         width: 50%;
//         opacity: 0;
//         z-index: 2;
//         animation: fadeIn 1s 1.8s forwards;
//     }

//     .home-page {

//         .text-zone {
//             position: absolute;
//             left: 10%;
//             top: 70%;
//             transform: translateY(-50%);
//             width: 85%;
//             max-height: 90%;
//         }
    
//         h1 {
//             color: #000;
//             font-size: 53px;
//             margin: 0;
//             font-family: 'Coolvetica';
//             font-weight: 400;
    
//             // <---------- HTML TAG Decor -------->
//             // &::before {
//             //     content: '<h1>';
//             //     font-family: 'La Belle Aurore';
//             //     color: #000;
//             //     font-size: 18px;
//             //     position: absolute;
//             //     margin-top: -40px;
//             //     left: 15px;
//             //     opacity: 0.6;
//             // }
    
//             // &::after {
//             //     content: '</h1>';
//             //     font-family: 'La Belle Aurore';
//             //     color: #000;
//             //     font-size: 18px;
//             //     position: absolute;
//             //     margin-top: 18px;
//             //     left: 20px;
//             //     animation: fadeIn 1s 1.7s backwards;
//             //     opacity: 0.6;
//             // }
    
//             // img {
//             //     width: 32px;
//             //     margin-left: 20px;
//             //     opacity: 0;
//             //     height: auto;
//             //     animation: rotateIn 1s linear both;
//             //     animation-delay: 1.4s;
//             // }
//         }
    
//         h2 {
//             color: #8d8d8d;
//             margin-top: 20px;
//             font-weight: 400;
//             font-size: 18px;
//             font-family: sans-serif;
//             letter-spacing: 3px;
//             animation: fadeIn 1s 1.8s backwards;
    
            
//         }
//     }
// }