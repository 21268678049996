// .container {
//     display: flex;
//     justify-content: space-between; /* Adjust as needed */
//     align-items: center; /* Center vertically */
// }

.image-container-about {
    // flex: 1; /* Takes up available space */
    position: relative;
}

.image-container-about img {
    margin-left: 50%;
    margin-top: 5%;
}

.artist-image {
    border-radius: 100%;
    width: 30%;
}

.tree-guy-about {
    // border-radius: 100%;
    position: absolute;
    top: 55%;
    right: 41%;
    height: auto;
    width: 15%;
    opacity: 0;
    z-index: 1;
    animation: fadeIn 1s 2s forwards !important;
    // animation: fadeIn 1s forwards;
}

.mystery-about {
    // border-radius: 100%;
    position: absolute;
    top: -10%;
    right: 18%;
    height: auto;
    width: 18%;
    opacity: 0;
    z-index: 1;
    animation: fadeIn 1s 2.5s forwards !important;
    // animation: fadeIn 1s forwards;
}

// .text-zone {
//     flex: 1; /* Takes up available space */
//     margin-right: 20px; /* Space between text and image */
// }

// Media Queries

@media screen and (max-width: 450px) {

    .image-container-about img {
        margin-left: 100px;
        margin-top: 5%;
        width: 50%;
    }

    .artist-image {
        position: initial;
    }
    
    .tree-guy-about {
        // border-radius: 100%;
        position: initial;
        display: none;
        top: 55%;
        right: 41%;
        height: auto;
        width: 15%;
        opacity: 0;
        z-index: 1;
        animation: fadeIn 1s 2s forwards !important;
        // animation: fadeIn 1s forwards;
    }
    
    .mystery-about {
        // border-radius: 100%;
        position: initial;
        display: none;
        top: -10%;
        right: 18%;
        height: auto;
        width: 18%;
        opacity: 0;
        z-index: 1;
        animation: fadeIn 1s 2.5s forwards !important;
        // animation: fadeIn 1s forwards;
    }
}