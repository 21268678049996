.nav-bar {
    background: #FFF;
    // background: #CCCCFF;
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;
    // display: flex;
    // flex-direction: column;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 3px auto;
            width: 80px;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }

    .logo2 {
        display: block;
        // padding: 8px 0;

        img {
            display: block;
            margin: 3px auto;
            width: 60px;
            height: auto;
        }
    }

    .anchor-logo {
        display: block;
        width: 70%;
        padding: 12px;

        img {
            display: block;
            margin: 3px auto;
            width: 60px;
            height: auto;
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            // color: #4d4d4e;
            color: #FFD700;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #000;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'ABOUT';
                }
            }

            &.contact-link {
                &::after {
                    content: 'CONTACT';
                }
            }

            &.gallery-link {
                &::after {
                    content: 'GALLERY';
                }
            }

            &.etsy-link {
                &::after {
                    content: 'ETSY';
                }
            }

            &.patreon-link {
                &::after {
                    content: 'PATREON';
                }
            }

            &.linktree-link {
                &::after {
                    content: 'LINKTREE';
                }
            }

            
        }

        a.active { 
            svg {
                color: #000;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                
            }
            &:hover svg {
                color: #000;
            }
        }
    }
}


.hamburger-icon,
.close-icon {
    display: none;
}

//Media Queries

// @media (max-width: 768px) {
//     .nav-bar {
//         width: 100%;
//         height: 70px;
//         position: fixed;
//         bottom: 0;
//         top: auto;
//         display: flex;
//         align-items: center;
//         justify-content: space-around;
//         flex-direction: row;
//         flex-wrap: wrap;
//         overflow-x: auto;

//         nav {
//             position: static;
//             height: auto;
//             margin: 0;
//             width: auto;
//             display: flex;
//             align-items: center;
//             justify-content: space-around;
//             flex-wrap: wrap;

//             a {
//                 font-size: 16px;
//                 line-height: 40px;
//             }
//         }

//         ul {
//             position: static;
//             bottom: auto;
//             margin-top: 10px;
//         }
//     }
// }



@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        // .logo {
        //     display: flex;
        //     padding: 8px 0;
        //     width: 90px;
    
        //     img {
        //         display: flex;
        //         margin: 3px auto;
        //         width: 60px;
        //         height: auto;
        //     }
        // }

        .anchor-logo {
            
            width: 5%;
            padding: 12px;
    
            img {
                
                margin: 3px auto;
                width: 60px;
                height: auto;
            }
        }

        ul, nav {
            display: none;
        }

        nav {
            width: 100%;
            height: 100%;
            background: #181818;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
            align-content: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;
            }
    
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
            }

            &.mobile-show {
                display: block;
            }
        }

        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top: 35px;
            right: 15px;
        }

        .hamburger-icon-v2,
        .close-icon-v2 {
            display: block;
            position: absolute;
            top: 35px;
            right: 15px;
        }
        
    }
}



@media screen and (max-width: 440px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        // .logo {
        //     display: flex;
        //     padding: 8px 0;
        //     width: 90px;
    
        //     img {
        //         display: flex;
        //         margin: 3px auto;
        //         width: 60px;
        //         height: auto;
        //     }
        // }

        .anchor-logo {
            
            width: 15%;
            padding: 12px;
    
            img {
                
                margin: 3px auto;
                width: 60px;
                height: auto;
            }
        }

        ul, nav {
            display: none;
        }

        nav {
            width: 100%;
            height: 100%;
            background: #181818;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
            align-content: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: auto;
            }
    
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
            }

            &.mobile-show {
                display: block;
            }
        }

        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top: 35px;
            right: 15px;
        }

        .hamburger-icon-v2,
        .close-icon-v2 {
            display: block;
            position: absolute;
            top: 35px;
            right: 15px;
        }
        
    }
}