.gallery {
    // -webkit-column-count: 3;
    // -moz-column-count: 3;
    // column-count: 3;
    // columns: 3;
    // -webkit-column-width: 33%;
    // -moz-column-width: 33%;
    // column-width: 33%;
    // padding: 0 12px;
    // padding: 12px 12px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 12px;
}

//Individual Pictures
.gallery .pictures {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;;
}

.gallery .pictures.fade-out {
    opacity: 0;
    transform: scale(0.95);
}

.gallery .pictures.fade-in {
    opacity: 1;
    transform: scale(0.95);
}

.gallery .pictures:hover {
    filter: opacity(.8);
}

.gallery-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 85%;
    vertical-align: middle;
    display: table-column;
    // display: flex;
    // flex-direction: column;
    // height: 100vh;
    max-height: 90%;
    overflow: auto;

    h1 {
        font-size: 53px;
        font-family: 'Coolvetica';
        color: #000;
        font-weight: 400;
        margin-top: 0;
        position: relative;
        margin-bottom: 40px;
        left: 10px;

    }

    p {
        font-size: 13px;
        color: #000;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.2s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.3s;
        }
    }
}



// model

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    // height: 100vh; /* Ensures full viewport height */
    overflow: auto; /* Allow scrolling if content overflows */
}


// Dropdown Menu

.dropdown {
    position: absolute;
    top: 6rem;
    // right: 1.5rem;
    width: 160px;
    user-select: none;
    padding: 3px;
    border-radius: 5px;
    background-color: #F5F5FF;
    border: 1px solid gray;
    // margin-bottom: 60px;
    margin-top: 10px;
    margin-left: 10px;

    .dropdown-btn{
        padding: 10px;
        // background: #71c17c;
        background: #845cc2;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
        font-weight: bold;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dropdown-content{
        position: absolute;
        top: 110%;
        padding: 15px;
        background: #fff;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
        font-weight: 500;
        color: #333;
        width: 95%;
        transition: height 0.3s ease, opacity 0.3s;
        animation: fadeIn .2s;
    }

    .dropdown-content .dropdown-item {
        padding: 10px;
        cursor: pointer;
        transition: all 0.2s;
    }

    .dropdown-content .dropdown-item:hover {
        background: #f4f4f4;
    }
}

.dropdown.open .dropdown-content {
    height: auto;
    opacity: 1;
}


.dropDown::before {
    content: '';
    position: absolute;
    top: -0.7rem;
    right: 1.1rem;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: #ffffff;
}


// Media Queries

@media screen and (max-width: 1200px) {
    
}

@media (max-width: 991px) {
    .gallery-zone {
        position: relative;
        left: 0;
        width: 100%;
        transform: translateY(0);
    }

    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    
}

@media (max-width: 480px) {
    .dropdown {
        margin-left: 30px;
    }

    .gallery-zone {
        position: relative;
        left: 0;
        width: 100%;
        transform: translateY(0);
    }

    .gallery-zone h1 {
        left: 30px;
    }

    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        padding: 30px;
    }
}